import React, { useContext, useEffect, useRef, useState, Fragment } from 'react'
import Heading from './heading/Heading'
import * as ColStyles from './Blocks.module.scss'
import Block from './block/Block'
import Slider from 'react-slick'
import '../../../static/slick.min.css'
import '../../../static/slick-theme.min.css'
import * as RecommendedArticlesStyles from '../organisms/recommendedArticles/RecommendedArticles.module.scss'
import {
  LittleBlackBook,
  NationalPosts,
  NationalReaderTreat,
  Post
} from '../../types'
import ArrowIcon from '../../assets/arrow-left.svg'
import { BPTLContext, BPTLStates } from '../../lib/utils'
import Leaderboard from '../../components/Banners/Leaderboard/Leaderboard'
import MPUBanner from '../../components/Banners/MPUBanner/MPUBanner'

interface ISettings {
  prefix?: string
  sponsoredBy?: string
  position?: number
  sponsorLogo?: string
  spotlightText?: string
  alignment?: string
  backgroundColour?: string
}

interface BlocksProps {
  posts: NationalPosts[] | Post[] | LittleBlackBook[] | NationalReaderTreat[]
  title?: string
  hasSpotlight?: boolean
  blockSlug?: string
  alignRight?: boolean
  backgroundColourOverride?: string
  settings?: ISettings
  maxResults?: number
  hasSlider?: boolean
  darkMode?: boolean
  isBptl?: boolean
  leaderboard?: string
  mpu?: boolean
}

const backgroundtoCss: Record<string, string> = {
  White: ColStyles.BackgroundColourWhite,
  Blue: ColStyles.BackgroundColourBlue,
  DarkTeal: ColStyles.BackgroundColourDarkTeal,
  MediumPink: ColStyles.BackgroundColourMediumPink,
  Beige: ColStyles.BackgroundColourBeige,
  LightGrey: ColStyles.BackgroundColourLightGrey,
  IceBlue: ColStyles.BackgroundColourIceBlue,
  LightPink: ColStyles.BackgroundColourLightPink,
  BabyPink: ColStyles.BackgroundColourBabyPink,
  MediumBlue: ColStyles.BackgroundColourMediumBlue,
  LightBeige: ColStyles.BackgroundColourLightBeige,
  BabyBeige: ColStyles.BackgroundColourBabyBeige
}

const slickSettings = {
  dots: false,
  infinite: true,
  autoplay: false,
  slidesToShow: 4,
  className: ColStyles.Slider,
  initialSlide: 0,
  centerMode: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        swipeToSlide: true
      }
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2,
        swipeToSlide: true
      }
    },
    {
      breakpoint: 420,
      settings: {
        slidesToShow: 1,
        swipeToSlide: true
      }
    }
  ]
}

const Blocks = ({
  darkMode = false,
  posts,
  title,
  hasSpotlight,
  alignRight,
  backgroundColourOverride,
  settings,
  maxResults,
  hasSlider,
  isBptl,
  leaderboard,
  mpu,
  blockSlug
}: BlocksProps) => {
  const blockRef = useRef(null)
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const [preVisible, setPreVisible] = useState<boolean>(false)
  const startCounter = hasSpotlight ? 1 : 0
  const endCounter = hasSpotlight
    ? isBptl
      ? 10
      : title === 'Cotswolds Special' || title === 'East Anglia Special'
      ? maxResults || 13
      : 9
    : maxResults || 8

  const blockSlider = useRef<Slider | null>(null)
  const bptlState = useContext(BPTLContext)
  const [isMobile, setIsMobile] = useState(false)
  const intervalRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (typeof window !== undefined) {
      setIsMobile(window.matchMedia('(max-width: 768px)').matches)
      setIsVisible(true)
      setPreVisible(true)
    }
  }, [])

  const callBackFunction = (entries: any) => {
    const [entry] = entries
    if (!preVisible && entry.isIntersecting) {
      setPreVisible(entry.isIntersecting)
    }
  }

  useEffect(() => {
    if (preVisible && !isVisible) {
      intervalRef.current = setTimeout(() => {
        setIsVisible(true)
      }, 3000)
    }

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current)
    }
  }, [preVisible])

  const intersectionOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 0.1
  }

  useEffect(() => {
    const observer = new IntersectionObserver(
      callBackFunction,
      intersectionOptions
    )
    if (blockRef.current) observer.observe(blockRef.current)

    return () => {
      if (blockRef.current) observer.unobserve(blockRef.current)
    }
  }, [blockRef, intersectionOptions])

  const alignment = alignRight ? 'Right' : settings?.alignment || null
  const backgroundColour = backgroundColourOverride
    ? backgroundColourOverride
    : settings?.backgroundColour || null

  const {
    prefix,
    sponsoredBy,
    position = 5,
    sponsorLogo,
    spotlightText
  } = settings ?? {}

  const handleSliderPrev = () => {
    blockSlider.current?.slickPrev()
  }

  const handleSliderNext = () => {
    blockSlider.current?.slickNext()
  }

  const ConditionalSlider = ({ children }: { children: any }) => {
    if (!children) return null
    if (hasSlider) {
      return (
        <>
          <Slider {...slickSettings} ref={blockSlider}>
            {children}
          </Slider>
          <div className={RecommendedArticlesStyles.Pagination}>
            <div
              className={RecommendedArticlesStyles.Left}
              onClick={() => handleSliderPrev()}
            >
              <img src={ArrowIcon} alt="Previous" />
            </div>
            <div
              className={RecommendedArticlesStyles.Right}
              onClick={() => handleSliderNext()}
            >
              <img src={ArrowIcon} alt="Next" />
            </div>
          </div>
        </>
      )
    } else {
      return children
    }
  }

  const getPrimaryCategory = (
    post: NationalPosts | Post | LittleBlackBook | NationalReaderTreat
  ) => {
    if ('primaryCategory' in post) return post.primaryCategory

    return null
  }

  const getPrimaryCategoryURI = (
    post: NationalPosts | Post | LittleBlackBook | NationalReaderTreat
  ) => {
    if ('primaryCategory' in post) return post.primaryCategoryURI

    return null
  }

  return (
    <div
      className={`${ColStyles.Cols} ${
        alignment === 'Right' ? ColStyles.RightAligned : ''
      } ${
        darkMode
          ? ColStyles.DarkMode
          : backgroundColour
          ? backgroundtoCss[backgroundColour]
          : ''
      }`}
      style={{ '--order': position } as React.CSSProperties}
      ref={blockRef}
    >
      {title && <Heading darkMode={darkMode}>{title}</Heading>}
      {sponsoredBy && (
        <div className={ColStyles.SponsoredBy}>
          <p>{prefix}</p>
          {sponsorLogo && <img src={sponsorLogo} />}
        </div>
      )}
      <div
        className={`${ColStyles.Col} ${
          !hasSpotlight ? ColStyles.FullWidth : ''
        } ${hasSlider ? ColStyles.HasSlider : ''}`}
      >
        <ConditionalSlider>
          {posts &&
            posts.slice(startCounter, endCounter).map((post, i) => (
              <Fragment key={post.id}>
                <div className={ColStyles.BPTLNumberWrapper}>
                  {isBptl &&
                    (bptlState === BPTLStates.NATIONAL ||
                      bptlState === BPTLStates.NATIONAL_CLOSED) && (
                      <div className={ColStyles.BPTLNumber}>
                        <p>#{i + 2}</p>
                      </div>
                    )}
                  <Block
                    darkMode={darkMode}
                    id={post.id}
                    key={post.id}
                    {...('featuredImage' in post && {
                      image: post.featuredImage?.node?.spotlightDesktop
                    })}
                    {...('homepagePreviewImage' in post && {
                      image: post.homepagePreviewImage
                    })}
                    {...('spotlightMobileImage' in post && {
                      image: post.spotlightMobileImage
                    })}
                    uri={post.uri ?? ''}
                    primaryCategory={getPrimaryCategory(post)}
                    primaryCategoryURI={getPrimaryCategoryURI(post)}
                    title={post.title}
                    linkType={'linkType' in post ? post.linkType : null}
                    isVisible={isVisible}
                  />
                </div>
                {i === 3 && leaderboard && isMobile && (
                  <Leaderboard
                    sticky={blockSlug === 'TRENDING'}
                    adSlot={leaderboard}
                  />
                )}
                {i === 5 && mpu && isMobile && (
                  <div style={{ width: '100%' }}>
                    <MPUBanner
                      adSlots={['Row1MPU1', 'Row1MPU2', 'Row1MPU3', 'Row1MPU4']}
                    />
                  </div>
                )}
                {i === 7 && mpu && !isMobile && (
                  <div style={{ width: '100%' }}>
                    <MPUBanner
                      adSlots={['Row1MPU1', 'Row1MPU2', 'Row1MPU3', 'Row1MPU4']}
                    />
                  </div>
                )}
                {i === 11 && mpu && isMobile && (
                  <div style={{ width: '100%' }}>
                    <MPUBanner
                      adSlots={['Row2MPU1', 'Row2MPU2', 'Row2MPU3', 'Row2MPU4']}
                    />
                  </div>
                )}
                {i === 15 && mpu && !isMobile && (
                  <div style={{ width: '100%' }}>
                    <MPUBanner
                      adSlots={['Row2MPU1', 'Row2MPU2', 'Row2MPU3', 'Row2MPU4']}
                    />
                  </div>
                )}
              </Fragment>
            ))}
        </ConditionalSlider>
      </div>
      {posts &&
        hasSpotlight &&
        posts.slice(0, 1).map(post => (
          <div
            className={`${ColStyles.Col} ${ColStyles.Spotlight}`}
            key={post.id}
          >
            <Block
              id={post.id}
              {...('spotlightMobileImage' in post && {
                image: post.spotlightMobileImage
              })}
              {...('featuredImage' in post && {
                image: post.featuredImage?.node?.spotlightDesktop
              })}
              key={post.id}
              uri={post.uri ?? ''}
              primaryCategory={getPrimaryCategory(post)}
              primaryCategoryURI={getPrimaryCategoryURI(post)}
              title={post.title}
              linkType={'linkType' in post ? post.linkType : null}
              isSpotlight
              spotlightText={spotlightText}
              isVisible={isVisible}
            />
          </div>
        ))}
    </div>
  )
}

export default Blocks
